import { MutableRefObject } from 'react';
import { SCROLL_OFFSET, DESKTOP_SCROLL_OFFSET } from '@config/general';
import jump from 'jump.js';
import { NAVIGATION_ITEMS } from '@config/navigationItems';

export const scrollTo = (
  target: string | MutableRefObject<any> | HTMLElement | number
) => {
  let element: HTMLElement;

  const opts = {
    duration: 2000,
    offset:
      (typeof window !== 'undefined' && window.innerWidth) <= 1024
        ? SCROLL_OFFSET
        : DESKTOP_SCROLL_OFFSET,
  };

  if (typeof target === 'number') {
    jump(target, { ...opts, offset: 0 });
  }

  if (target === NAVIGATION_ITEMS[0].href) {
    jump(0, { ...opts, offset: 0 });
  }

  if (typeof target === 'string') {
    element = document.querySelector(target);
  } else if ((target as MutableRefObject<any>).current) {
    element = (target as MutableRefObject<unknown>).current as HTMLElement;
  } else {
    element = target as HTMLElement;
  }

  jump(element, opts);
};
