import React from 'react';
import styled from 'styled-components';

export default styled.p`
  font-size: ${({ theme }) => theme.bodyXS};
  line-height: 1.9;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyM};
    line-height: 2.2;
  }
`;
