import { scrollTo } from '@utils/scrollTo';
import React from 'react';

export const goToSection = (e: React.MouseEvent, target: string) => {
  if (target.charAt(0) === '/' && window.location.pathname === '/') {
    e.preventDefault();
    scrollTo(target.substr(1, target.length));
  } else if (target.charAt(0) !== '/') {
    e.preventDefault();
    scrollTo(target);
  }
};
