import { createContext } from 'react';

interface Context {
  width: number;
  height: number;
}

const Context = createContext<Context>({ width: null, height: null });

export default Context;
