import React from 'react';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  html {
    font-size: 10px;
  }
  
  body {
    font-size: ${({ theme }) => theme.bodyS};
    font-family: ${({ theme }) => theme.primaryFont};
    color: ${({ theme }) => theme.foreground};
    background: ${({ theme }) => theme.background};
    margin: 0;
    width: 100%;
  }
  
  .fb_dialog_content iframe, .fb_dialog {
    z-index: 99!important;
  }
  
  #___gatsby {
    overflow: hidden;
  }
`;
