import { css, keyframes } from 'styled-components';

export const rippleAnimation = keyframes`
  0% {
    opacity: 0.6;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

export const rippleStyles = css`
  transition: background 0.3s ease-in-out, transform 0.3s ease-out;

  .ripple {
    width: 160px;
    height: 160px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }
`;
