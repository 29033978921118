import { Icons } from '@config/icons';
import phoneIcon from '@iconify-icons/fa-solid/phone-alt';
import universityIcon from '@iconify-icons/fa-solid/university';
import trainIcon from '@iconify-icons/ic/sharp-train';
import verifiedIcon from '@iconify-icons/ic/sharp-verified-user';
import basketIcon from '@iconify-icons/ic/round-local-grocery-store';
import parkIcon from '@iconify-icons/foundation/trees';
import locationIcon from '@iconify-icons/ic/baseline-location-on';
import angleLeft from '@iconify-icons/la/angle-left';
import angleRight from '@iconify-icons/la/angle-right';
import angleUp from '@iconify-icons/uil/angle-up';
import angleLeftBold from '@iconify-icons/uil/angle-left';
import check from '@iconify-icons/ic/baseline-check';
import email from '@iconify-icons/ic/baseline-email';
import download from '@iconify-icons/ic/baseline-download';

export const getIconifyIcon = (icon: Icons) => {
  switch (icon) {
    case 'phone':
      return phoneIcon;
    case 'train':
      return trainIcon;
    case 'verified':
      return verifiedIcon;
    case 'basket':
      return basketIcon;
    case 'university':
      return universityIcon;
    case 'park':
      return parkIcon;
    case 'location':
      return locationIcon;
    case 'angle-left':
      return angleLeft;
    case 'angle-left-bold':
      return angleLeftBold;
    case 'angle-right':
      return angleRight;
    case 'angle-up-bold':
      return angleUp;
    case 'check':
      return check;
    case 'email':
      return email;
    case 'download':
      return download;
  }
};

export const useIcon = (icon: Icons) => getIconifyIcon(icon);

export const useIcons = (icons: Icons[]) =>
  icons.map((icon) => getIconifyIcon(icon));
