import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Hamburger from '@components/atoms/Hamburger/Hamburger';
import { Link } from 'gatsby';
import { Routes } from '@routes';
import { NAVIGATION_ITEMS } from '@config/navigationItems';
import logo from '@assets/images/logo.svg';
import useNavigation from '@components/organisms/Navigation/useNavigation';
import Context from '@context';
import { useWindowScroll } from 'react-use';

interface StyledProps {
  $isActive?: boolean;
  $isCurrent?: boolean;
  $isScroll?: boolean;
}

const StyledWrapper = styled.nav<StyledProps>`
  position: fixed;
  height: 55px;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  z-index: 99;

  @media (min-width: 1025px) {
    height: 100px;
    padding: 0 3%;
    justify-content: space-between;
    transition: height 0.5s ease-in-out, background 0.5s ease-in-out;
  }

  @media (min-width: 1260px) {
    padding: 0 8%;
  }

  ${({ $isScroll }) =>
    $isScroll &&
    css`
      height: 80px !important;
      background: rgba(0, 0, 0, 0.6);
    `}
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 1025px) {
    position: static;
    transform: none;
    left: unset;
    top: unset;
  }
`;

const StyledPlainLink = styled.a`
  color: inherit;
  text-decoration: none;
  padding: 11px 20px;
  display: block;
  text-align: center;
  width: 100%;

  @media (min-width: 1025px) {
    padding: 0;
  }
`;

const StyledLogo = styled.img`
  width: 40px;
  height: auto;

  @media (min-width: 1025px) {
    width: 70px;
  }
`;

const StyledList = styled.ul<StyledProps>`
  position: absolute;
  top: 100%;
  left: 0;
  background: ${({ theme }) => theme.background};
  list-style-type: none;
  padding: 0 0 11px 0;
  margin: 0;
  width: 100%;
  box-shadow: 20px 30px 70px #00000033;
  font-size: ${({ theme }) => theme.bodyS};
  transform: scaleY(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform-origin: top left;
  opacity: 0;
  pointer-events: none;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: scaleY(1);
      opacity: 1;
      pointer-events: all;
    `};

  @media (min-width: 1025px) {
    position: static;
    transform: unset;
    opacity: 1;
    box-shadow: unset;
    background: transparent;
    display: flex;
    padding: 0;
    width: unset;
    font-size: ${({ theme }) => theme.bodyM};
    pointer-events: all;
  }
`;

const StyledItem = styled.li<StyledProps>`
  @media (min-width: 1025px) {
    color: #fff;
    margin-right: 35px;
    :last-of-type {
      margin-right: 0;
    }
  }

  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      background: ${({ theme }) => theme.primary};
      color: #fff;

      @media (min-width: 1025px) {
        background: transparent;
        color: ${({ theme }) => theme.primary};
      }
    `};
`;

const Navigation = () => {
  const {
    isActive,
    currentPlace,
    handleButtonClick,
    handleItemClick,
  } = useNavigation();

  const { width } = useContext(Context);
  const { y: scroll } = useWindowScroll();

  return (
    <StyledWrapper $isScroll={width >= 1025 && scroll > 200}>
      {width <= 1024 && (
        <Hamburger isActive={isActive} onClick={handleButtonClick} />
      )}

      <StyledLink aria-label="Strona główna" to={Routes.Index}>
        <StyledLogo src={logo} alt="Diamentowe Zacisze" aria-hidden="true" />
      </StyledLink>
      <StyledList $isActive={isActive}>
        {NAVIGATION_ITEMS.map(({ name, href }, index) => (
          <StyledItem key={index} $isCurrent={index === currentPlace}>
            <StyledPlainLink
              href={href}
              onClick={(e) => handleItemClick(e, href)}
            >
              {name}
            </StyledPlainLink>
          </StyledItem>
        ))}
      </StyledList>
    </StyledWrapper>
  );
};

export default Navigation;
