import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useCookie } from 'react-use';

interface StyledProps {
  $isActive: boolean;
}

const StyledWrapper = styled.div<StyledProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 80px;
  background: #1a1a18;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
  z-index: 2147483644;

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
      pointer-events: all;
    `};
`;

const StyledCookieInfo = styled.span`
  color: ${({ theme }) => theme.gray100};
  margin-bottom: 15px;
`;

const StyledLinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -5px;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primary100};
  margin-right: 10px;
  margin-bottom: 5px;
`;

const StyledButton = styled.button`
  position: absolute;
  right: 10px;
  top: 15px;
  background: ${({ theme }) => theme.primary};
  color: #1a1a18;
  border: 0;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font-weight: ${({ theme }) => theme.regular};
  font-family: 'Roboto', sans-serif;

  @media (max-width: 1024px) {
    position: static;
    margin-top: 20px;
    width: 100%;
  }
`;

const Cookie: FC<Props> = ({ canBeDisplayed, setCanBeDisplayed }) => {
  const [cookie, setCookie] = useCookie('cookie-information');

  const handleOnClick = () =>
    setCookie('cookie-information', {
      expires: new Date().getDate() + 7,
    });

  useEffect(() => {
    setTimeout(() => setCanBeDisplayed(true), 1000);
  }, []);

  return (
    <>
      <StyledWrapper $isActive={!cookie && canBeDisplayed}>
        <StyledCookieInfo>
          Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu
          do serwisu oraz prowadzenia danych statystycznych. Dalsze korzystanie
          z tej witryny oznacza akceptację tego stanu rzeczy.
        </StyledCookieInfo>
        <StyledLinksWrapper>
          <StyledLink
            href="https://diamentowezacisze.pl/polityka-prywatnosci"
            target="_blank"
            rel="noopener"
          >
            Polityka prywatności
          </StyledLink>
        </StyledLinksWrapper>
        <StyledButton onClick={handleOnClick}>Akceptuję</StyledButton>
      </StyledWrapper>
    </>
  );
};

interface Props {
  canBeDisplayed: boolean;
  setCanBeDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default Cookie;
