import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  primary: '#E7B52E',
  primary100: '#D6A41D',
  gray400: '#3d3d3d',
  gray300: '#777777',
  gray200: '#888888',
  gray100: '#a8a8a8',
  background: '#fff',
  foreground: '#1a2a2a',

  available: '#32cd32',
  reserved: '#E7B52E',
  unavailable: '#ff6347',

  primaryFont: "'Roboto', sans-serif",
  secondaryFont: "'Palatino Linotype', serif",

  headingXL: '12.7rem',
  headingL: '9.4rem',
  headingM: '6.4rem',
  headingS: '4.5rem',
  headingXS: '3.4rem',
  headingXXS: '3.0rem',

  bodyXXL: '2.4rem',
  bodyXL: '2.2rem',
  bodyL: '2.0rem',
  bodyM: '1.8rem',
  bodyS: '1.6rem',
  bodyXS: '1.4rem',
  bodyXXS: '1.2rem',

  regular: 400,
  medium: 500,
  bold: 700,
};
