import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import Context from '@context';
import { rgba } from 'polished';

interface StyledProps {
  $secondary?: boolean;
  $behindTextSize?: string;
}

const StyledWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ $secondary }) =>
    !$secondary &&
    css`
      flex-direction: column;
    `}
`;

const StyledHeadline = styled.h2<StyledProps>`
  text-align: center;
  font-size: ${({ theme }) => theme.bodyXXL};
  width: 85%;
  margin: 0;
  font-weight: ${({ theme }) => theme.bold};

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingS};
    max-width: 780px;
  }

  ${({ $secondary }) =>
    $secondary &&
    css`
      margin: 0 12.5%;
      width: unset;

      @media (min-width: 1025px) {
        font-size: ${({ theme }) => theme.headingM};
      }
    `};
`;

const StyledBehindText = styled.span<StyledProps>`
  position: absolute;
  z-index: -1;
  color: ${({ theme }) => rgba(theme.primary, 0.2)};
  font-size: 7.6rem;
  font-weight: ${({ theme }) => theme.bold};
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: 1025px) {
    font-size: 30rem;
    max-width: 98vw;
  }

  ${({ $behindTextSize }) =>
    $behindTextSize &&
    css`
      font-size: ${$behindTextSize}!important;
    `}
`;

const StyledLine = styled.span`
  background: ${({ theme }) => theme.gray400};
  flex: 1;
  height: 1px;
`;

const StyledSecondaryText = styled.span`
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.bodyXL};
  margin-bottom: 20px;
`;

const Headline: FC<Props> = ({
  children,
  behindText,
  className,
  secondary,
  behindTextSize,
  id,
  secondaryText,
  ...props
}) => {
  const { width } = useContext(Context);

  return (
    <StyledWrapper className={className} $secondary={secondary} {...props}>
      {secondary && <StyledLine />}
      {width >= 1025 && secondaryText && (
        <StyledSecondaryText
          data-aos={!secondary ? 'fade-up' : undefined}
          data-aos-duration="500"
          data-aos-delay="500"
        >
          {secondaryText}
        </StyledSecondaryText>
      )}
      <StyledHeadline
        $secondary={secondary}
        id={id}
        data-aos={!secondary ? 'fade-up' : undefined}
        data-aos-duration="500"
        data-aos-delay="1000"
      >
        {children}
      </StyledHeadline>
      {!secondary ? (
        <StyledBehindText
          $behindTextSize={width <= 1024 ? behindTextSize : undefined}
          data-aos={!secondary ? 'zoom-out-up' : undefined}
          data-duration="500"
        >
          {behindText}
        </StyledBehindText>
      ) : (
        <StyledLine />
      )}
    </StyledWrapper>
  );
};

interface Props {
  behindText?: string;
  behindTextSize?: string;
  secondaryText?: string;
  className?: string;
  secondary?: boolean;
  id?: string;
}

export default Headline;
