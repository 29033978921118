import { useEffect, useState, MouseEvent, useContext } from 'react';
import { scrollTo } from '@utils/scrollTo';
import { NAVIGATION_ITEMS } from '@config/navigationItems';
import { DESKTOP_SCROLL_OFFSET, SCROLL_OFFSET } from '@config/general';
import Context from '@context';
import { goToSection } from '@utils/goToSection';

const useNavigation = () => {
  const [isActive, setActive] = useState<boolean>(false);
  const [currentPlace, setCurrentPlace] = useState<number>(0);
  const [offsets, setOffsets] = useState<number[]>(null);
  const { width } = useContext(Context);

  const checkOffsets = () => {
    offsets.forEach((offset, index) => {
      if (
        (typeof window !== 'undefined' && window.pageYOffset) +
          (width <= 1024 ? -SCROLL_OFFSET : -DESKTOP_SCROLL_OFFSET) >=
          offset &&
        currentPlace !== -1
      ) {
        setCurrentPlace(index);
      }
    });
  };

  const handleButtonClick = () => {
    checkOffsets();
    setActive((prevState) => !prevState);
  };

  const handleItemClick = (e: MouseEvent, target: string) => {
    setActive(false);

    goToSection(e, target);
  };

  useEffect(() => {
    let offsets: number[] = [];

    NAVIGATION_ITEMS.forEach(({ href }, index) => {
      offsets = [
        ...offsets,
        document
          .querySelector(
            href.charAt(0) === '/' ? href.substr(1, href.length) : href
          )
          ?.getBoundingClientRect().top +
          (typeof window !== 'undefined' && window.pageYOffset),
      ];

      if (Number.isNaN(offsets[index])) {
        setCurrentPlace(-1);
      }
    });

    setOffsets(offsets);

    return () => {
      setOffsets([]);
    };
  }, []);

  useEffect(() => {
    if (
      NAVIGATION_ITEMS.length === offsets?.length &&
      width >= 1025 &&
      currentPlace !== -1
    ) {
      document.addEventListener('scroll', checkOffsets);
    }

    return () => {
      document.removeEventListener('scroll', checkOffsets);
    };
  }, [NAVIGATION_ITEMS.length === offsets?.length && width >= 1025]);

  return {
    isActive,
    currentPlace,
    handleButtonClick,
    handleItemClick,
  };
};

export default useNavigation;
