import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ScrollTopButton from '@components/atoms/ScrollTopButton/ScrollTopButton';
import { NAVIGATION_ITEMS } from '@config/navigationItems';
import { scrollTo } from '@utils/scrollTo';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import logo from '@assets/images/logo.svg';
import { goToSection } from '@utils/goToSection';

const StyledWrapper = styled.footer`
  margin-top: 85px;
  background: #1a1a18;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: 1025px) {
    background: transparent;
    align-items: center;
    width: 95%;
    margin: 120px 0 0 auto;
    max-width: 1800px;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;
  }

  @media (min-width: 1050px) {
    width: 90%;
    margin: 120px auto 0;
  }

  @media print {
    display: none;
  }
`;

const StyledLeftColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1025px) and (max-width: 1329px) {
    width: 100%;
  }
`;

const StyledRightColumn = styled.div`
  display: none;

  @media (min-width: 1320px) {
    display: flex;
    flex-direction: column;
    width: 20%;
  }

  @media (min-width: 1420px) {
    width: 25%;
    padding-right: 5%;
  }
`;

const StyledLogo = styled.img`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 150px;
    margin: 40px 0;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 10px 0 0 0;
  color: ${({ theme }) => theme.background};

  @media (min-width: 1025px) {
    color: ${({ theme }) => theme.foreground};
    display: flex;
  }
`;

const StyledItem = styled.li`
  text-align: center;
  font-size: ${({ theme }) => theme.bodyS};

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyL};
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 10px 0;
  display: block;

  @media (min-width: 1025px) {
    padding: 0;
    margin-right: 50px;
  }
`;

const StyledScrollTopButton = styled(ScrollTopButton)`
  position: absolute;
  right: 20px;
  top: 0;
  transform: translateY(-50%);

  @media (min-width: 1025px) and (max-width: 1329px) {
    top: unset;
    bottom: 50px;
    transform: translateY(0);
  }
`;

const StyledAuthor = styled.span`
  color: ${({ theme }) => theme.gray200};
  font-size: ${({ theme }) => theme.bodyXS};
  text-align: center;

  @media (min-width: 1025px) {
    display: block;
    order: 4;
    margin-top: 20px;
    font-size: ${({ theme }) => theme.bodyS};
    text-align: left;
  }
`;

const StyledAuthorLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const StyledCopyright = styled.span`
  color: ${({ theme }) => theme.gray200};
  font-size: ${({ theme }) => theme.bodyS};
  width: 90%;
  margin: 25px auto;
  text-align: center;
  line-height: 1.6;

  @media (min-width: 1025px) {
    display: block;
    width: unset;
    text-align: left;
    order: 3;
    margin: 0;
    font-size: ${({ theme }) => theme.bodyM};
  }
`;

const StyledContactParagraph = styled(Paragraph)`
  margin-bottom: 7px;
  line-height: 1;
  color: ${({ theme }) => theme.gray200};

  :last-of-type {
    margin-bottom: 0;
    line-height: 1.3;
  }
`;

const StyledInformation = styled.span`
  margin-top: 20px;
  color: ${({ theme }) => theme.gray200};
`;

const Footer = () => {
  const {
    datoCmsContact: { eMail, location, phoneNumber },
  } = useStaticQuery(query);

  return (
    <StyledWrapper>
      <StyledScrollTopButton />
      <StyledLeftColumn>
        <nav>
          <StyledList>
            {NAVIGATION_ITEMS.map(({ name, href }, index) => (
              <StyledItem key={index}>
                <StyledLink to={href} onClick={(e) => goToSection(e, href)}>
                  {name}
                </StyledLink>
              </StyledItem>
            ))}
          </StyledList>
        </nav>

        <StyledLogo src={logo} alt="Diamentowe Zacisze" />

        <StyledAuthor>
          Stworzone z miłością przez{' '}
          <b>
            <StyledAuthorLink
              href="https://rend.pro/offer/investment-website/"
              target="_blank"
              rel="noopener"
            >
              RendPro.com
            </StyledAuthorLink>
          </b>
        </StyledAuthor>
        <StyledCopyright>
          2021 &copy; Diamentowe Zacisze. All Rights Reserved.
        </StyledCopyright>
      </StyledLeftColumn>
      <StyledRightColumn>
        <StyledContactParagraph>{phoneNumber}</StyledContactParagraph>
        <StyledContactParagraph>{eMail}</StyledContactParagraph>
        <StyledContactParagraph>{location}, Polska</StyledContactParagraph>
        <StyledInformation>
          Treści znajdujące się na stronie internetowej nie stanowią oferty w
          rozumieniu przepisów prawa i winny być interpretowane wyłącznie jak
          informacja, o których jest mowa w art. 71 Kodeksu Cywilnego.
        </StyledInformation>
      </StyledRightColumn>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsContact {
      eMail
      phoneNumber
      location
    }
  }
`;

export default Footer;
