import { useContext, useState } from 'react';
import Context from '@context';

export const useButtonEffects = () => {
  const [isPressed, setPressed] = useState<boolean>(false);
  const { width } = useContext(Context);

  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPressed(true);
    document.addEventListener('mouseup', () => setPressed(false));

    const span = document.createElement('span');
    const rects = e.currentTarget.getBoundingClientRect();

    const halfRippleWidth = width <= 1024 ? 80 : 130;

    const left = e.clientX - rects.left - halfRippleWidth;
    const top = e.clientY - rects.top - halfRippleWidth;
    span.classList.add('ripple');

    span.style.left = `${left}px`;
    span.style.top = `${top}px`;

    e.currentTarget.appendChild(span);

    setTimeout(() => span.remove(), 600);

    return () => {
      document.removeEventListener('mouseup', () => setPressed(false));
    };
  };
  const onMouseUpAndTouchEnd = () => setPressed(false);

  const onTouchStart = () => {
    setPressed(true);
    document.addEventListener('touchend', () => setPressed(false));

    return () => {
      document.removeEventListener('touchend', () => setPressed(false));
    };
  };

  return { isPressed, onMouseDown, onMouseUpAndTouchEnd, onTouchStart };
};
