import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@iconify/react';
import { useIcon } from '@hooks/useIcon';
import { useButtonEffects } from '@hooks/useButtonEffects';
import { rippleStyles } from '@theme/ripple';
import { scrollTo } from '@utils/scrollTo';

interface StyledProps {
  $isPressed: boolean;
}

const StyledButton = styled.button<StyledProps>`
  width: 65px;
  height: 65px;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.background};
  border-radius: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 4rem;
  overflow: hidden;
  cursor: pointer;

  ${({ $isPressed }) =>
    $isPressed &&
    css`
      transform: scale(0.95) translateY(-50%) !important;
    `}

  ${rippleStyles}
`;

const ScrollTopButton: FC<Props> = ({ className }) => {
  const {
    isPressed,
    onMouseDown,
    onMouseUpAndTouchEnd,
    onTouchStart,
  } = useButtonEffects();

  const onClick = () => scrollTo('body');

  return (
    <StyledButton
      className={className}
      $isPressed={isPressed}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUpAndTouchEnd}
      onTouchEnd={onMouseUpAndTouchEnd}
      onTouchStart={onTouchStart}
      onClick={onClick}
      aria-label="Przewiń na początek strony"
    >
      <Icon icon={useIcon('angle-up-bold')} />
    </StyledButton>
  );
};

interface Props {
  className?: string;
}

export default ScrollTopButton;
