import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';
import hamburgerIcon from '@iconify-icons/gg/menu-left-alt';
import closeIcon from '@iconify-icons/gg/close';

const StyledButton = styled.button`
  background: none;
  border: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 2.6rem;
`;

const Hamburger: FC<Props> = ({ isActive, onClick }) => (
  <StyledButton
    aria-label={isActive ? 'Zamknij nawigację' : 'Otwórz nawigację'}
    onClick={onClick}
  >
    <Icon icon={isActive ? closeIcon : hamburgerIcon} aria-hidden="true" />
  </StyledButton>
);

interface Props {
  isActive: boolean;
  onClick: () => void;
}

export default Hamburger;
